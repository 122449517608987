import L from "leaflet"; 
import { useMap } from "react-leaflet";
import { useEffect } from "react";

function OnLive({IsOnlive}){

    const map=useMap(); 

    useEffect(()=>{
        const onlive=L.control({position:"topleft"}); 

        onlive.onAdd=()=>{

            if(IsOnlive===false){
                const div=L.DomUtil.create('div'); 
                div.innerHTML='<div style="display:flex; align-items:center; color:#FFFAFA; justify-content:space-around; width:50px; background-color:#111111; padding:3px; border-radius:3px ">onlive <span style="height:6px; width:6px; border-radius:100%; padding:2px; background-color:red"></span> </div>'; 
                return div;
            }

            const div=L.DomUtil.create('div'); 
            div.innerHTML='<div style="display:flex; align-items:center; color:#FFFAFA; justify-content:space-around; width:50px; background-color:#111111; padding:3px; border-radius:3px ">onlive <span style="height:6px; width:6px; border-radius:100%; padding:2px; background-color:green"></span> </div>'; 
            return div;
        }

        onlive.addTo(map); 

        return ()=>{
            map.removeControl(onlive); 
        }

    },[map, IsOnlive]); 

    return null; 
}

export default OnLive; 