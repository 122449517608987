import Template from "../template/Template";
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from "../pages/ErrorPage";
import Auth from "../pages/Auth";


export const router=createBrowserRouter([
    {
      path:"/",
      loader:async()=>{
        const response2=await fetch(`https://app.neyos.fr/api/v2/balises`);
        const data=await response2.json(); 
        
        return data;  
      }, 
      element: <Template/>,
      errorElement:<ErrorPage/>,
    }, 
    {
      path:"Auth",
      element:<Auth/>
    }
]); 