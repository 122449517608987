import { List } from 'rsuite';
export default function Config({data}){

    console.log(data); 
    
    const dataData=
   
        <>
            <List>
                {/* <List.Item >Identifiant balise : <span className='font-bold'>{data.ID?data.ID:"Aucun donnée"}</span></List.Item> */}
                <List.Item >N°balise : <span className='font-bold'>{data.N_Balise?data.N_Balise:"Aucune donnée"}</span></List.Item>
                <List.Item >Accord commercial : <span className='font-bold'>{data.Accord_commercial?data.Accord_commercial:"Aucun donnée"}</span></List.Item>
                <List.Item >Date d'assemblage : <span className='font-bold'>{data.Assembl_le?data.Assembl_le:"Aucun donnée"}</span></List.Item>
                <List.Item >Status : <span className='font-bold'>{data.Fonctionnement?data.Fonctionnement:"Aucune donnée"} - {data.Statut?data.Statut:"Aucune donnée"}</span></List.Item>
                <List.Item >Intervalle de reveille/veille : <span className='font-bold'>{data.Intervalle_de_r_veille_pendant_la_phase_de_veille_h?data.Intervalle_de_r_veille_pendant_la_phase_de_veille_h:"Aucune donnée"}</span></List.Item>
                <List.Item >Intervalle d'envoie NBIOT_s: <span className='font-bold'>{data.Interval_d_envoie_NBIOT_s?data.Interval_d_envoie_NBIOT_s:"Aucune donnée"}</span></List.Item>
                <List.Item >Intervalle d'envoie SAT : <span className='font-bold'>{data.Intervalle_d_envoie_SAT?data.Intervalle_d_envoie_SAT:" Aucune donnée"}</span></List.Item>
                <List.Item >Temps de mise en veille : <span className='font-bold'>{data.Temps_avant_mise_en_veille_s?data.Temps_avant_mise_en_veille_s:"Aucune donnée"}</span></List.Item>
                <List.Item >Consommation total ko : <span className='font-bold'>{data.Consommation_totale_Ko?data.Consommation_totale_Ko:"Aucune donnée"}</span></List.Item>
                <List.Item >Intervalle de position : <span className='font-bold'>{data.Intervalle_de_prise_de_position_s?data.Intervalle_de_prise_de_position_s:"Aucune donnée"}</span></List.Item>
                <List.Item >Intervalle d'envoie CATM : <span className='font-bold'>{data.Interval_d_envoie_CATM?data.Interval_d_envoie_CATM:"Aucune donnée"}</span></List.Item>
            </List>
        </>
 

    return (
        <>     
          {dataData}
          
        </>
    )
}