import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import bgGround from "../assets/img/Splash-600.jpg";
import logNeyos from "../assets/icon/logo_white.png";
import {useState,useEffect} from "react";


export default function Auth(){

    const { login, register, logout } = useKindeAuth();
    const divStyle = {
        // width: '100%',
        // height: '400px',
        backgroundImage: `url(${bgGround})`,
        backgroundSize: 'cover', // Pour couvrir tout le conteneur
        backgroundPosition: 'center', // Centrer l'image
        backgroundRepeat: 'no-repeat' // Ne pas répéter l'image
    };


    const [id, setId]=useState(""); 

    return (
        <>
           <div class="min-h-screen space-y-6  p-[5rem]"  style={divStyle}>
                <section class="mx-auto w-[50%] mt-[4rem] flex justify-center">
                    <img src={logNeyos} alt="" srcset="" class="h-[60px] w-[60px] self-center"/>
                </section>

                <div class="bg-neutral-800 flex justify-center   mx-auto w-[28%] p-[2rem] rounded shadow-sm">
                    <button class="text-center bg-blue-500 p-2 rounded text-neutral-200"  onClick={()=>login({authUrlParams:{ username:id}})} type="button">Je m'authentifie ! </button>
                </div>
            </div>
        </>
    )
}