import { useMap, useMapEvents } from "react-leaflet";
export default function MapEvent({zoomEvtLimit}){
    
    const map=useMap(); 

    useMapEvents({
        // click(e){
        //     map.panTo(e.latlng);
        // }, 
        zoomend: () => {
            const newZoom = map.getZoom();   

            switch(newZoom){
               
                case 5:
                    zoomEvtLimit(true);
                    break; 
                case 18:
                    zoomEvtLimit(true);
                    break;
                default:
                    zoomEvtLimit(false);
            }

          }
    })
    
    return null; 
}