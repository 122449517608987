import { useState, useCallback, useEffect } from "react";
import axios from "axios"; 



export function useApiBalise(user,isAuth,url){
  
    const [data,setData]=useState([]); 
    const [dataIsLoading,setDataIsLoading]=useState(true); 

    const baliseAPI=useCallback(async()=>{
        try{

            if(isAuth){
               
                const response=await axios.get(`${url}/${user.given_name}`); 
                 
                if(response.status  === 200){

                    setData(await response.data);
                    
                    setDataIsLoading(false); 
        
                }else{

                    throw new Error("Vous avez une erreur dans la requette"); 
                }

            }        

        }catch(err){
            console.error(`Vous avez ce message d'erreur : ${err}`); 
        }


    },[isAuth,user,url]); 


    useEffect(()=>{

        baliseAPI(); 

    },[baliseAPI]); 


    return {data,dataIsLoading}; 

}
