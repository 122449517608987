import L from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect } from "react";

function NotBalise() {
    const map = useMap();

    useEffect(() => {
        if (!map) return; // Assurez-vous que la carte est initialisée

        const onlive = L.control({ position: "topleft" }); // Utilisez 'topleft' en minuscules

        onlive.onAdd = () => {
            const div = L.DomUtil.create('div');
            div.innerHTML = `
                <div style="display:flex; align-items:center; color:#FFFAFA; justify-content:space-around; width:250px; background-color:#111111; padding:3px; border-radius:3px;">
                    Message : Aucune balise n'est affectée à votre compte !
                </div>`;
            return div;
        };

        onlive.addTo(map);

        return () => {
            if (map && onlive) map.removeControl(onlive);
        };
    }, [map]);

    return null;
}

export default NotBalise;
