// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  z-index: 1;
}


.sidebar{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f7f7;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,6BAA6B;EAC7B,UAAU;AACZ;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".leaflet-container {\r\n  width: 100% !important;\r\n  height: 100% !important;\r\n  position: relative !important;\r\n  z-index: 1;\r\n}\r\n\r\n\r\n.sidebar{\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 100vh;\r\n  background-color: #f7f7f7;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
