// import Statut from './Statut';
import ChartCanva from '../chart/ChartCanva';
import Timelines from './TimeLines';
import Config from './Config';
import { Tabs} from 'rsuite';

export default function Min_nav({detailsBalise}){
  


    return (
    
        <>
        <Tabs defaultActiveKey="1" appearance="subtle">
            <Tabs.Tab eventKey="1" title="Configuration">
              <Config data={detailsBalise}/> 
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title="Graphique">
              <ChartCanva />
            </Tabs.Tab>
            <Tabs.Tab eventKey="3" title="Historique">
               <Timelines data={detailsBalise} /> 
            </Tabs.Tab>
        </Tabs>
                     
        </>
    )
}