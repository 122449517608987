import CanvasJSReact from '@canvasjs/react-charts';
import { useEffect, useRef, useState } from 'react';
export default function ChartDynamic(){

    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    
    const [dataPoints, setDataPoints] = useState([]);
    const chartRef = useRef(null);
  
    useEffect(() => {
      // Simule l'ajout de nouvelles données toutes les secondes
      const interval = setInterval(() => {
        const newDataPoint = {
          x: new Date(),
          y: Math.floor(Math.random() * 100) + 1 // Génère une valeur aléatoire entre 1 et 100
        };
        setDataPoints(prevDataPoints => [...prevDataPoints, newDataPoint]);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.render();
      }
    }, [dataPoints]);
  
    const options = {
      animationEnabled: true,
      theme: "light2",
      title: {
        text: "L'intensité du signal en temps réel"
      },
      axisY: {
        title: "",
        includeZero: false
      },
      data: [
        {
          type: "line",
          dataPoints: dataPoints
        }
      ]
    };

    return (
        <>
          <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
        </>
    )
}

