import L from "leaflet"; 
import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { Button,Modal } from "rsuite";

function Notification({message}){

    const map=useMap(); 
    

     /**
      * Modal Notification
     */

     const [modalNotification,setModalNotification]=useState(false); 
     
    

    useEffect(()=>{
          
        const Alert=L.control({position:"bottomleft"}); 

        Alert.onAdd=()=>{
           
            const div=L.DomUtil.create("div"); 
            div.innerHTML=`

               <div style="position:relative; text-align:center; diplay:flex;">
                   <div style="width:16px; height:16px; background-color:black; position:absolute; top:1px; right:-3px; padding:2px; border-radius:100%; font-size:9px; color:#FFFAFA">${message.length === 0 ? 0 : message.length}</div>
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-7" style="cursor:pointer;">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                   </svg>  
               </div>
                
            `; 

            L.DomEvent.on(div,"click",()=>setModalNotification(!modalNotification)); 
            
            return div; 
            
        }


        Alert.addTo(map); 

        return ()=>{
            map.removeControl(Alert); 
        }

    },[map,message]); 



    return  modalNotification 
    
    && 

    <Modal size={400} backdrop="static"  open={modalNotification} onClose={()=>setModalNotification(false)}>
        <Modal.Header>
            <Modal.Title>Notification</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {
                message.length === 0 ? 
                
                <div className="flex justify-center text-md">
                    <h1 className="text-sm text-neutral-600">vous n'avez aucun message !</h1>
                </div>

                :

                <ul className="space-y-2 text-sm">
                    {
                        message.map((m,index)=>
                        <li className="p-2 bg-slate-50 mb-2 rounded flex items-center justify-between" key={index}>
                            <div>
                            <p><strong>Objet :</strong> Notification</p>
                            <span className="text-xs">{m}</span>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>

                        </li>
                    )
                    }
                </ul>
            }
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={()=>setModalNotification(false)} appearance="primary">
                Ok
            </Button>
            <Button onClick={()=>setModalNotification(false)} appearance="subtle">
                Cancel
            </Button>
        </Modal.Footer>
    </Modal> 
    
}

export default Notification; 