import { useMap } from 'react-leaflet';
import {
    Marker,
    Popup, Tooltip, useMapEvent, Circle

} from 'react-leaflet'; 
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css'
import { useEffect, useCallback } from 'react';
import L from "leaflet"
import MarkerClusterGroup from 'react-leaflet-markercluster';


export default function BaliseMarker({data,getWayPt,imsi, index, displayByUser}){

     
    const map=useMap();



    const ZoomSingleMarker=(data, lat,lon, i)=>{
        getWayPt(prev=>[...prev,data]); 
        index(i); 

        map.flyTo([lat,lon],6, {
            animate:true,
            duration:1
        });
    }

    /**
     * La variable displayByUser se charge
     * d'afficher uniquement les informations 
     * des balises concernant un utilisateurs
     * 
     * Si elle est commenter, elle affichera
     * toute les balises quelque soit l'utilisateur...
     */ 

    const displayMap=data?.map((bal, index)=>

            displayByUser.includes(bal.imsi) &&

            <Marker key={index} position={[bal.lat, bal.lon]} eventHandlers={{dblclick:()=>ZoomSingleMarker(bal,bal.lat,bal.lon, bal.imsi)}}>
                <Popup>
                    <div className='text-xs'>

                        <p><strong>Imsi: </strong>{bal.imsi}</p>
                        <div className='flex space-x-2'>
                            <span><strong>Latitude: </strong>{bal.lat}</span>
                            <span><strong>Longitude: </strong>{bal.lon}</span>
                        </div>
                        <p><strong>Alert:</strong> {bal.alert}</p>
                        <p><strong>Dernières position: </strong>{bal.lat}  <strong>-</strong> {bal.lon}</p>
                        <p><strong>N°batterie: </strong>{bal.bat} %</p>
                    </div>
                </Popup>

                { imsi !== undefined && imsi === bal.imsi && <Circle center={[bal.lat, bal.lon]} radius={5000}/>}

                

                <Tooltip>
                    <div className='flex flex-col'>
                        <span>{bal.imsi}</span>
                        <span><strong>lat:</strong>{bal.lat} - <strong>long:</strong>{bal.lon}</span>
                        <span><strong>batterie:</strong>{bal.bat} %</span>
                    </div>
                </Tooltip>
            </Marker> 
       
        
        
    
    ); 

    return (

        <>
           {displayMap}
        </>
    )

}  