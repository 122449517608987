import UserImg from "../../assets/img/user.png";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
// import { NavLink } from "react-router-dom";
import { Dropdown, Avatar,  Affix  } from 'rsuite';
import { Badge } from 'rsuite';


export default function Header({fnIconFilter}){
    
  const { user, isAuthenticated, logout } = useKindeAuth();
  const renderToggle = props => (
    <Avatar circle {...props} src={UserImg} />
  );

    return (
        <>
       
            <div className="flex bg-neutral-900 text-neutral-200 justify-between px-4 items-center h-[3.8rem] ">
                


                <div className="flex justify-start w-[46.5%]">
                   {
                    isAuthenticated && (
                      <>
                         <p className=" text-2xl">Bienvenue {user.given_name} ! </p>
                         <p className="bg-yellow-500 text-neutral-800  text-xs rounded-xl px-2 flex ml-2 items-center font-semibold">Beta</p>
                      </>
                      
                      
                    )
                   }
                </div>
                
                  <div className='flex space-x-6 items-center relative'>
                
                       <svg onClick={fnIconFilter} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="stroke-white cursor-pointer w-6 h-6 transition easy-in-out hover:stroke-slate-200">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                        </svg>

                        <div className='flex flex-col group text-neutral-700'>
        
                            <Dropdown renderToggle={renderToggle}  placement="leftStart">
                              <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                                <p>Connectez en tant que</p>
                                <strong>{isAuthenticated && ( user.given_name)}</strong>
                              </Dropdown.Item>
                              <Dropdown.Separator />
                              <Dropdown.Item style={{color:"red"}} onClick={logout}>Déconnexion</Dropdown.Item>
                            </Dropdown>
                        </div>               
                  </div>
            </div>
            
        </>
    )
}