import { Sidenav, Nav,Heading,  Loader, Affix, Drawer, Container, Breadcrumb, IconButton, Sidebar, Content, Stack, HStack, useToaster, Message } from 'rsuite';
import {
    MdSettings,
    MdKeyboardArrowLeft,
    MdOutlineKeyboardArrowRight,
    MdSearch
} from 'react-icons/md';
import logNeyos from "../assets/icon/logo_dark.png"; 
import { Icon } from '@rsuite/icons';
import ExploreIcon from '@rsuite/icons/Explore';
import 'rsuite/dist/rsuite.min.css';
import Min_nav from '../Components/detailsbalises/Min_Nav';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { useEffect, useState } from 'react';
import SearchBar  from '../Components/sidebar/SearchBar';
import {NavLink, useLoaderData } from 'react-router-dom';
import FilterModal from "../Components/Filter/FilterModal";
import Header from "../Components/header/Header"; 
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import NoAccess from '../pages/NoAccess';
import {baliseContext} from "../hooks/context/balisesContext"; 
import { useDataFilter, useSearchBalise } from '../hooks/useDataFilter';
import { useApiBalise } from '../hooks/useApiBalise';


/**Map */

import {
    MapContainer,
    TileLayer,
    LayersControl,
    ZoomControl
    
} from 'react-leaflet'; 
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import "leaflet-control-geocoder"; 
import BaliseMarker from "../Components/map/BaliseMarker";
import BaliseWaypoint from '../Components/map/BaliseWaypoint';
import SingleMarker from '../Components/map/SingleMarker';
import MapEvent from '../Components/map/MapEvent';
import OnLive from '../Components/map/OnLive';
import Notification from '../Components/map/Notification';
import { useWebSocket } from '../hooks/useWebSocket';
import {io} from "socket.io-client"; 
import NotBalise from '../Components/map/NotBalise';


export default function Template(){

/**
 * IMSI ID l'imsi de la balise selectionner...
 */
const [imsi,setImsi]=useState(undefined); 

const [expand, setExpand] = useState(false);

/***système d'authentification de Kinde */

const { user, isAuthenticated } = useKindeAuth();



/***
 * Le Hook useApibalise renvoie la variable data ( contient les données recuperer par l'appelle API)
 * et dataisLoading ( boolean ? "isLoading":"data")
 */
const {data,dataIsLoading}=useApiBalise(user,isAuthenticated,"https://app.neyos.fr/api/neyos/zoho/client");


/**
 * Le Hook useDataFilter contient des variables et des fonctions. Les variables renvoyées contiennent les données recuperer 
 * a partir des composants contenu dans le composant FilterModal.Vous pouvez utiliser les variables suivantes pour effectuer
 * vos filtres: [checkbox[], slide[], color[], dataDebut{}, dataFin{}, tagPickerSelect[], heureDebut,heureFin]
 * 
 * NB: La variable getFilterData est un variable contenant l'ensemble des méthodes utiliser pour
 * recuperer les donnnées a partir des composant enfants...
 */
const {getFilterData}=useDataFilter(); 


/***
 * Le Hook useSearchBalise renvoie les données filtrer par le champ de recherche
 * et la variable dataSearch...
*/

const {dataSearch, setTextSearch, textSearch}=useSearchBalise(data);


const [size,setsize]=useState(400); //taille du modal qui sort de gauche...
const [charticon, setChartIcon]=useState(false); 


 
/***
 * Fonctions des composants
*/



const [open, setOpen] =useState(false);
const [placement, setPlacement] =useState();
const [selectedItemID, setSelectedItemID] = useState("");

/*****Chargement données */


const handleOpen = (key,item )=> {
  setOpen(!open);
  setPlacement(key);  
  setSelectedItemID(item);
};

let index=0; // Val de l'index....



/**
 * Methode de la 
 * sidebar content les détails de la balises selectionner...
 */
const ChangeSizeModal = ()=> {
    
    setChartIcon(!charticon);

    if(charticon!==true){
        setsize("80rem");
    }else{
        setsize(400);
    }
};


let OpenDetailsBalise=""; 

if(charticon===false){

    OpenDetailsBalise=<>
       <svg onClick={ ChangeSizeModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>
    </>
}else{

    OpenDetailsBalise=<>
       <svg onClick={ ChangeSizeModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
        </svg>
    </>
}



/**
 * Contient les items de la sideBar de gauche...
 */

const SideBar_MenuIten=<>

    <Nav.Menu eventKey="3" title="Balises" icon={<ExploreIcon />}>

        {
            dataIsLoading===false?
            dataSearch.map((balises_data)=> 
            <>
                <Nav.Item key={balises_data.ID} eventKey={"3-"+index++}  onClick={()=>setImsi(balises_data["PCB.IMSI"])}>
                        <div  className='flex flex-col'>
                                <div className='flex space-x-4 items-center'>
                                <span className='font-bold'>Balise n° {balises_data.N_Balise}</span>
                                <RemindRoundIcon onClick={() => handleOpen('right',balises_data )} />
                                </div>
                                <div className='space-x-4 flex'>
                                    <span className='text-xs text-neutral-800'>Intervalle de Position: <span className='text-neutral-700 font-bold'>{balises_data.Intervalle_de_prise_de_position_s}  m/s</span></span>
                                    <span className='text-xs text-neutral-800'>Intervalle de Veille: <span className='text-neutral-700 font-bold'>{balises_data.Temps_avant_mise_en_veille_s} m/s</span> </span>
                                    <span className='text-xs text-neutral-800'>Consommation totale: <span className='text-neutral-700 font-bold'>{balises_data.Consommation_totale_Ko?balises_data.Consommation_totale_Ko:0} Ko</span> </span>
                                </div>       
                        </div>
                </Nav.Item>
            </>
       
            )
            : (
                <>
                 
                  <Loader className='ml-3' speed="slow" size="md" content="Chargement..."/>
                   
                </>
             )
            
            
        }

        {
            <p className='ml-[3.5rem] mt-2 text-sm text-neutral-600'>{ dataIsLoading===false && dataSearch.length===0?`Aucune balise ne correspond a "${textSearch}"`:""}</p>
        }
        
    </Nav.Menu>


</>


/**
 * Bouton ouverture du composant Modal filtre...
*/

const [FilterOpen,setFilterOpen]=useState(false); 
const handleFilterOpen=()=>setFilterOpen(true); 
const handleFilterClose=()=>setFilterOpen(false); 



const NavToggle = ({ expand, onChange }) => {
    return (
      <Stack className="nav-toggle" justifyContent={expand ? 'flex-end' : 'center'}>
        <IconButton
          onClick={onChange}
          appearance="subtle"
          size="lg"
          icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
        />
      </Stack>
    );
  };
  
  const Brand = ({ children, expand }) => {
    return (
      <HStack className="page-brand" style={{marginTop:3, padding:5}}>
        <Stack alignItems='center' spacing={12}>
            <NavLink  to="/">
                <img src={logNeyos} style={{width:50}} />
            </NavLink>
            {expand &&  <Heading level={3}>Neyos</Heading>}
        </Stack>
      </HStack>
    );
  };
  

  /**
   * MAP 
   * */

     const center=[48.8566, 2.3522]; 
     /**
      * Données des balises recuperer de l'API...
      */
    //  const LatLongData=useLoaderData();

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    }); 

    const {BaseLayer,Overlay}=LayersControl; 

    /***
     * LayersControl : C'est le conteneur pour les couches contrôlées par l'utilisateur. 
     * Il gère les options que l'utilisateur peut sélectionner pour basculer entre différentes couches.

      BaseLayer : Ces couches sont exclusives. L'utilisateur peut en sélectionner une seule à la fois. 
      Dans l'exemple, les couches "OpenStreetMap" et "Satellite" sont des couches de fond, et "OpenStreetMap" est sélectionnée par défaut (checked).
     Overlay : Ces couches sont indépendantes les unes des autres. 
        L'utilisateur peut les activer ou les désactiver individuellement. 
        Dans l'exemple, "Cities" est une couche de superposition qui peut être affichée ou masquée en plus de la couche de fond sélectionnée.
     */

    const [markerWp,setMarkerWp]=useState([]); 

    /**
     * Index pour les waypoints a afficher...
     */

    const [baliseIndex,setbaliseIndex]=useState(undefined); 
    

    /**
     * Map Zoom limite
    */

    const Toaster=useToaster(); 
    const [zoomLimit,setZoomLimit]=useState(false); 
    const WarningZoomLimit=
    <Message showIcon type="warning" header="Limite atteinte" closable>
        Vous avez atteint la limite autorisée.
    </Message>; 
    
    useEffect(()=>{
        zoomLimit &&
        Toaster.push(WarningZoomLimit,{ placement:"bottomEnd", duration: 3000 }); 
    },[zoomLimit]); 
   
    /**
     * balise Modal waypoint
     */

    const [wayPtModal,setWayPtModal]=useState(false); 
    const [wayPtData,setWayPtData]=useState([]); 

    /**
     * Notification alert...
     */ 
    const [notificationAlert,setNotificationAlert]=useState([]);
    
   

    
    const newNotification=
    <Message showIcon type='success' header="Centre de Notification" closable>
         Vous avez un nouveau message !
    </Message>

    useEffect(()=>{
        notificationAlert.length>0 && 
        Toaster.push(newNotification,{placement:"topCenter", duration:3000}); 
    },[notificationAlert]);    

    useEffect(()=>{
        
        const socket=io(`http://localhost:3001`); 
        socket.on("nouvelle donnée",(data,rep)=>{
             setNotificationAlert(prev=>[...prev,data]); 
             rep("Client : Merci pour l'information ..."); 
        }); 

        
    },[]);

    /***
     * useLoaderData() fat 
     * référence aux hook loader de react-router lié 
     * a cette page
     * 
    */
    const LoaderDataBalise=useLoaderData(); 
    
    const DB_Initial=LoaderDataBalise[0].series.flatMap(balise=>

        balise.values.map(value => ({
            imsi: balise.tags.imsi,
            lat: value[1],
            lon: value[2],
            alert: value[3],
            bat: value[4],
            ts: value[5] 
        })) 
    ); 

    /***
     * Appel au Webhook personnalisé...
     * Pour l'utilisation du webSocket...
    */
    const {LatLongData,onlive, resSocket}=useWebSocket(DB_Initial);
    
    /***
     * Pour afficher uniquement
     * les balises du client
     * j'affiche uniquement les balises affectés aux client
     * depuis la base de donnée Zohoo
     */
    const Balises_ZOHO=data.map((n)=>n["PCB.IMSI"]); 
    const Balises_INFLUX=DB_Initial.map((i)=>i.imsi); 
    /**
     * Recupère les IMSI communs entre les deux base de donnée
     * INFLUX DB && ZOHO appartenant au client
     */
    const ImsibalisesZohoInfluxDb=Balises_ZOHO.filter(b => Balises_INFLUX.includes(b)); 
   
    return (
                    
        <>
           
            <Container>
                <Affix>
                    <Sidebar
    
                        width={expand ? 370 : 56}
                        collapsible
                        className="sidebar">
                        <Sidenav.Header>
                            <Brand expand={expand} />
                        </Sidenav.Header>
                        <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
                            <Sidenav.Body>
                                <Nav defaultActiveKey="1">
                                    <Nav.Item eventKey="1" icon={<Icon as={MdSearch} />}>
                                        <SearchBar onSearch={setTextSearch} />
                                    </Nav.Item>
                                        { 
                                        SideBar_MenuIten
                                        }
                                    <Nav.Item eventKey="2" icon={<Icon as={MdSettings} />}>
                                        Paramètre
                                    </Nav.Item>
                                    
                                </Nav>
                            </Sidenav.Body>
                        </Sidenav>
                        <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
                    </Sidebar>
                </Affix>

                <Container>
                     {/****Header */}
                        <Header fnIconFilter={handleFilterOpen}/>

                        {FilterOpen && 
                        
                        <baliseContext.Provider value={data}>
                                <FilterModal FilterOpen={FilterOpen} OpenFunction={handleFilterOpen} CloseFunction={handleFilterClose} {...getFilterData}/>
                        </baliseContext.Provider>

                        }
                    <Content>

                     {/**
                         * La sidebar qui s'affiche lorsque 
                         * l'utilisateur cherche a voir les détails 
                         * concernant les données d'un balise
                         * en particulier
                         * 
                         * */}

                        {
                            selectedItemID && 
                                <Drawer  backdrop={false} size={size} placement={placement} open={open} onClose={() => setOpen(false)}>
                                    <Drawer.Header className='flex items-center'>
                                    <Drawer.Title>Détails balise </Drawer.Title>
                                    
                                   {OpenDetailsBalise /***Icon agrandissement...Details du Menu */} 
                                    </Drawer.Header>
                                    <Drawer.Body>
                                        {/***
                                         * 
                                         * Le menu contenu dans la sidebar 
                                         * des détails...
                                         * */}
                                        <Min_nav detailsBalise={selectedItemID}/>

                                    </Drawer.Body>
                               </Drawer>
                        }
                        
                     {/**
                     * Pour le contenu des pages c'est ici
                     * .... */
                     }

                   
                        {
                            /**
                             * map height : 763px
                             */
                            isAuthenticated ? (
                                
                                <MapContainer center={center} zoom={7} minZoom={3} maxZoom={18} zoomControl={false} scrollWheelZoom={true} className="leaflet-container">
                                        
                                        <ZoomControl position="bottomright" />

                                        <LayersControl position="topright">
                                            <BaseLayer checked name="OpenStreetMap">
                                                <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                            </BaseLayer>
                                            <BaseLayer  name="OpenStreetMap.HOT">
                                                <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                                                />
                                            </BaseLayer>

                                            <BaseLayer  name="OpenStreetMap.France">
                                                <TileLayer
                                                        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                />
                                            </BaseLayer>

                                            <BaseLayer  name="SmoothDark">
                                                <TileLayer
                                                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                                                />
                                            </BaseLayer>

                                            <BaseLayer  name="Satellite">
                                                <TileLayer
                                                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg"
                                                />
                                            </BaseLayer>
                                        </LayersControl>
                                         
                                        
                                        {
                                            markerWp.length === 0 && 
                                            <BaliseMarker displayByUser={ImsibalisesZohoInfluxDb} data={LatLongData} getWayPt={setMarkerWp} index={setbaliseIndex}  imsi={imsi}/>
                                        }
                
                                        {
                                            markerWp.length !==0 && <BaliseWaypoint index={baliseIndex} getWayPtData={setWayPtData} modal={setWayPtModal} modalData={wayPtModal}/>
                                        }

                                        {

                                            markerWp.length !==0 && <SingleMarker data={markerWp} getWayPt={setMarkerWp} />
                                            
                                        }
                                        
                                          
                                        {/* 

                                          Ceci est l'évènement qui affiche le message lorsque
                                          le zoom maximale est atteitn, il est pour l'instant désactivé
                                          
                                          =============balises===================
                                          <MapEvent zoomEvtLimit={setZoomLimit}/> 
                                        
                                        */}
                                        
                                        
                                        {
                                            wayPtData && 

                                                <Drawer size={200} placement="bottom" open={wayPtModal} onClose={() => setWayPtModal(false)}>
                                                    <Drawer.Body>
                                                        <div>

                                                            <ul>
                                                                {
                                                                    wayPtData.map((i)=>
                                                                        
                                                                       <>
                                                                            <li>
                                                                                {
                                                                                   i
                                                                                }
                                                                            </li>  
                                                                       </>
                                                                    )
                                                                }
                                                            </ul>
                                                            
                                                        </div>
                                                    </Drawer.Body>
                                                </Drawer>
                                            
                                        }



                                       <OnLive IsOnlive={onlive}/>

                                       <Notification message={notificationAlert}/>  
                                       

                                       {
                                        /**
                                         * Ce message s'affichera si l'utilisateur n'a aucune balise
                                         */
                                         LatLongData.length<0 && <NotBalise/>
                                       }
                                                                        
                                  
                                </MapContainer>
                                

                            ): <NoAccess/>
                        }

                    </Content>
                </Container>
            </Container>
        </>
            
    ); 
}