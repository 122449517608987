import { Modal, Button } from 'rsuite';
import SliderFilter from "./SliderFilter"; 
import TagPickerFilter from "./TagPickerFilter"; 
import DatePickerFilter from "./DatePickerFilter"; 
import FilterCheckbox from "./FilterCheckbox"; 
import ColorPicker from './ColorPicker';
import {CheckboxGroup } from 'rsuite';
import { sliderData, ColorData,checkboxData } from '../../utils/const';
import FilterTime from './FilterTime';


export default function FilterModal({
    FilterOpen,
    OpenFunction,
    CloseFunction, 
    handleCheckbox,
    handleChangeColor,
    handleRange,
    handleChangeDateDebut,
    handleChangeDateFin,
    handleHeureDebut,
    handleHeureFin,handleTagPicker  }){

let size="80rem"; 

//getTagPicker

const getTagPickerData=(val)=>{
    handleTagPicker(val);
}

//Checkbox selectionner
const getCheckbox=(val)=>{
   
    handleCheckbox(val); 
} 

//Couleur Selectionner

const getChangeColor=(name,color)=>{
    
    handleChangeColor(name,color); 
}


//Range "Slider"
const getRange=(name,val)=>{
    
    handleRange(name,val); 
}

//Date de Début 

const getChangeDateDebut = (name,val)=>{
    handleChangeDateDebut(name,val);
}

//Date de Fin 
const getChangeDateFin = (name,val)=>{
    handleChangeDateFin(name,val); 
}

//Heure debut


const getHeureDebut=(name, val)=>{
    handleHeureDebut(name, val); 
}

const getHeureFin=(name, val)=>{
    handleHeureFin(name, val); 
}


    return (

        <>
        
        <Modal size={size} open={FilterOpen} onClick={OpenFunction} onClose={CloseFunction}>
            <Modal.Header>
                <Modal.Title>Filtre</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <TagPickerFilter getTagPickerData={getTagPickerData} name="Sélectionnez vos balises"/>

               <div className='grid grid-cols-3 p-3 space-y-[2rem] '>
                    <div className='col-span-1 flex flex-col flex flex-col space-y-2'>       
                        <CheckboxGroup>
                            {
                                checkboxData.map((checkbox)=> (
                                    <>
                                      <FilterCheckbox key={checkbox.name} name={checkbox.name} value={checkbox.value} selectValue={getCheckbox}/>
                                    </>
                                ))
                            }

                        </CheckboxGroup>
                    </div>

                    <div className='col-span-1'>
                        {
                            ColorData.map((color)=>(
                                <><ColorPicker key={color.name} name={color.name}  value={color.value} handleChangeColor={getChangeColor}/></>
                            ))
                        }

                    </div>

                    <div className='col-span-1 space-y-[1rem]'>
                        {
                            sliderData.map((slider)=>(
                                <>
                                  <SliderFilter key={slider.key} name={slider.name} handleRange={getRange}/>
                                </>
                            ))
                        }
                    </div>

                    <div className='flex items-center space-x-5'>
                        <div className='space-y-2'>
                            <DatePickerFilter name="Date de début" handleChangeDateDebut={getChangeDateDebut}/>
                            <DatePickerFilter name="Date de Fin" handleChangeDateFin={getChangeDateFin}/>
                        </div>
                        <div className='space-y-2'>
                            <FilterTime name="Heure du début" getHeure={getHeureDebut}></FilterTime>
                            <FilterTime name="Heure de fin" getHeure={getHeureFin}></FilterTime>
                        </div>
                    </div>
                    
               </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={CloseFunction} appearance="subtle">
                    Annuler
                </Button>
                <Button onClick={CloseFunction} appearance="primary">
                    Filtrer
                </Button>
            </Modal.Footer>
         </Modal>
        
        </>
    )
}