import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useState } from "react";
import WayPoints from "./WayPoints";

export default function BaliseWaypoint({index,  getWayPtData, modal}) {
    const [baliseWP, setBaliseWp] = useState([]);


    const handleGetDataWayPoint=(dataWp)=>{
        
        getWayPtData(dataWp); 

        dataWp.length>0 && modal(true); 
    }; //Recupération des données du waypoints selectionné...

    const getData = useCallback(async () => {
        try {
            const response = await fetch(`http://localhost:3001/api/v2/balises/imsi/${index}`);

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            
            // Vérifie que les données sont un tableau
            if (Array.isArray(data)) {
                setBaliseWp(data);
            } else {
                setBaliseWp([]); // Gérer une réponse inattendue
            }
        } catch (err) {
            console.error(err);
        }
    }, [index]);

    useEffect(() => {
        getData();
    }, [getData]);

    // Ajout d'une vérification avant de rendre les WayPoints
    return baliseWP.length > 0 ? <WayPoints data={baliseWP} handleGetDataWayPoint={handleGetDataWayPoint} /> : <p>Chargement...</p>;
}
