import { TagPicker } from 'rsuite';
import {GeBalises} from "../../hooks/context/balisesContext";

export default function TagPickerFilter({name, getTagPickerData}){

    const balises=GeBalises(); 
    const data=balises.map(
        item => ({ label: item.N_Balise, value: item.ID })
      );

    const handleTagSelect=(val)=>{ 
        getTagPickerData(val); 
    }
      
    return (
        <>
            <div className='flex flex-col space-y-2'>
                <p className='font-bold'>{name}</p>
                <TagPicker data={data} blockn onSelect={handleTagSelect} />
            </div>
        </>
    )
}