export default function FilterTime({name, getHeure}){
    
    const handleChange=(e)=>{
       
        getHeure(e.target.name, e.target.value); 
    }
    
    return (
        <>
           <div className="flex flex-col space-y-2">
              <p className="font-bold">{name}</p>
              <input onChange={handleChange} type="time" name={name} className="p-[0.38rem] w-[8rem] rounded outline-blue-400 text-neutral-600 border"/>
           </div>
        </>
    )

}