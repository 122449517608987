import { useMap } from 'react-leaflet';
import {
    Marker,
    Popup, Tooltip, Circle

} from 'react-leaflet'; 

export default function SingleMarker({data,getWayPt}){

     
    const map=useMap(); 

    const ZoomSingleMarker=(lat,lon)=>{
        getWayPt([]); 
        map.flyTo([lat,lon],8, {
            animate:true,
            duration:1
        });
    }


    const displayMap=data.map((bal, index)=>
      
        <Marker key={index} position={[bal.lat,bal.lon]} eventHandlers={{dblclick:()=>ZoomSingleMarker(bal.lat,bal.lon)}}>
            <Popup>
                <div className='text-xs'>

                    <p><strong>Imsi: </strong>{bal.imsi?bal.imsi:"undefined"}</p>
                    <div className='flex space-x-2'>
                        <span><strong>Latitude: </strong>{bal.lat}</span>
                        <span><strong>Longitude: </strong>{bal.lat}</span>
                    </div>
                    <p><strong>Alert:</strong> {bal.alert}</p>
                    <p><strong>Dernières position: </strong>{bal.lat}  <strong>-</strong> {bal.lon}</p>
                    <p><strong>N°batterie: </strong>{bal.bat}%</p>
                </div>
            </Popup>

            {/* <Circle center={[bal[1],bal[2]]} radius={300}/> */}

            <Tooltip>
               <div className='flex flex-col'>
                    <span> <strong>lat:</strong>{bal.lat} - <strong>long:</strong>{bal.lon}</span>
                    <span><strong>batterie:</strong>{bal.bat}%</span>
               </div>
            </Tooltip>
        </Marker> 
    
    ); 

    return displayMap; 

}  