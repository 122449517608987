import { useCallback, useState, useEffect } from "react";

export function useDataFilter(){

    const [checkbox,setCheckbox]=useState([]); //Checkbox
    const [slide,setSlide]=useState([]); //Slide
    const [color,setColor]=useState([]); //Couleur selectionner
    const [dataDebut,setDateDebut]=useState({}); // Date de début
    const [dataFin,setDateFin]=useState({}); //date de Fin
    const [tagPickerSelect,setTagPickerSelect]=useState([]); //TagPicker
    const [heureDebut,setHeureDebut]=useState([]); 
    const [heureFin,setHeureFin]=useState([]); 

    //Tag Balisen selectionner
    const handleTagPicker=(val)=>{
    
    if(!tagPickerSelect.includes(val)){
        setTagPickerSelect(prev=>[...prev,val]);  
    }   
    } 



    //Checkbox selectionner

    const handleCheckbox=(val)=>{
    
        if(!checkbox.includes(val)){
            setCheckbox(prev => [...prev,val]); 
        }  
    } 



    //Couleur Selectionner

    const handleChangeColor=(name,color)=>{
        
        setColor(prev => [...prev, {name:name,color:color}]); 
    }

   
    //Range "Slider"
    const handleRange=(name,val)=>{
        
        setSlide(prev => [...prev, {name:name,val:val}]); 
    }

  

    //Date de Début 

    const handleChangeDateDebut = (name,val)=>{
        setDateDebut(prev=>[...prev,{name:name,value:val}]);
    }


    //Date de Fin 
    const handleChangeDateFin = (name,val)=>{
    setDateFin(prev=>[...prev,{name:name,value:val}]); 
    }



    //Heure Debut

    const handleHeureDebut=(name,val)=>{
        setHeureDebut(prev => [...prev,{name:name,val:val}]); 
    }



    //Heure Debut

    const handleHeureFin=(name,val)=>{
        setHeureFin(prev => [...prev,{name:name,val:val}]); 
    }

  


    //spread opérator

    const getFilterData={
        handleTagPicker,
        handleCheckbox,
        handleChangeColor,
        handleRange,
        handleChangeDateDebut,
        handleChangeDateFin, 
        handleHeureDebut, 
        handleHeureFin

    }

    return {checkbox,slide,color,dataDebut,dataFin,tagPickerSelect,heureDebut,heureFin,getFilterData}
  
}
//Fonction de recherche....

export function useSearchBalise(data){

    const [textSearch, setTextSearch]=useState(""); 
    const [dataSearch,setDataSearch]=useState([]); 

    useEffect(()=>{

        const search=data.filter((arr)=>arr.N_Balise.includes(textSearch));  
        setDataSearch(search); 
    
    },[textSearch,data]); 

    return {dataSearch,setTextSearch,textSearch}; 

}
