export default function SearchBar({onSearch}){


    return (
        <>
            <div className='flex'>
                <input type="text" onChange={(e)=>onSearch(e.target.value)} className='h-[2.56rem] w-[16.3rem] bg-slate-100 placeholder-neutral-700 rounded-sm outline-none focus:outline-neutral-700 border-slate-500  border-0 rounded-none px-2' placeholder='Rechercher une balise'/>
            </div>
        </>
    )
}