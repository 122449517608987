import { createContext, useContext } from "react";

export const baliseContext=createContext(undefined); //Creation du context....

export const GeBalises=()=>{

    const balises=useContext(baliseContext);

    if(balises===undefined){
       throw new Error ("La value doit contenir la data des balises"); 
    }

    return balises; 

}