import { Checkbox } from 'rsuite';
export default function FilterCheckbox({name, value, selectValue}){
    
    const handleClick=(value)=>{
       selectValue(value); 
    }


    return (
        <>       
            <Checkbox value={value} onChange={handleClick}> <span className='font-bold'>{name}</span></Checkbox>
        </>
    )
}