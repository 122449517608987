import bgGround from "../assets/img/Splash-600.jpg";
import logNeyos from "../assets/icon/logo_dark.png";
import { useRouteError } from "react-router-dom"
export default function ErrorPage(){
    

    const divStyle = {
        // width: '100%',
        // height: '400px',
        backgroundImage: `url(${bgGround})`,
        backgroundSize: 'cover', // Pour couvrir tout le conteneur
        backgroundPosition: 'center', // Centrer l'image
        backgroundRepeat: 'no-repeat' // Ne pas répéter l'image
    };


    let error=useRouteError(); 

    console.log(error); 
    
    return (
        <>
           <div className="flex justify-center items-center  min-h-screen" style={divStyle}>
               
                <div className="w-[50%] p-[3rem] bg-slate-50 space-y-2 text-center bg-white rounded h-[50%]">
                   <img src={logNeyos} alt="Logo" srcset="" className="w-[5rem] h-[5rem] mx-auto"/>
                    <h2>Oops this page  {error.statusText }</h2>
                    <h1>{error.status}</h1>
                    <p>{error.data}</p>
                </div>
           </div>
        </>
    )
}