import { DatePicker } from 'rsuite';
export default function DatePickerFilter({name}){

    const handleChange=(val)=>{
        
        console.log(val, name); 
    }

    return (
        <>
            <div className='flex flex-col flex flex-col space-y-2 mr-2'>
                <p className='font-bold'>{name}</p>
                <DatePicker name='name' onChange={handleChange} />
            </div>
           
        </>
    )
}