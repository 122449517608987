//Filter data - value
export const checkboxData=[
    {name:"Afficher les points GSM", value:"GSM"}, 
    {name:"Afficher les points SAT", value:"SAT"},
    {name:"Afficher les points BUFFER", value:"BUFFER"},
    {name:"Afficher les point REPIT", value:"REPIT"},
    {name:"Afficher les point GPX", value:"GPX"},

]; 

export const sliderData=[
    {name:"Diamètre de GSM"}, 
    {name:"Diamètre pour SAT"}, 
    {name:"Diamètre pour BUFFER"},
    {name:"Diamètre pour REPIT"},
    {name:"Diamètre pour GPX"}
]; 


export const ColorData=[
        {name:"Couleur pour GSM", value:"#ff5733"}, 
        {name:"Couleur pour SAT", value:"#33ff57"},
        {name:"Couleur pour BUFFER", value:"#3357ff"},
        {name:"Couleur pour REPIT (2 points)", value:"#f1c40f"},
        {name:"Couleur pour REPIT (>2 points)", value:"#9b59b6"},
        {name:"Couleur pour GPX", value:"#e74c3c"}
    
]; 


