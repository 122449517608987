export default function ColorPicker ({name, value, handleChangeColor }){
    
    const handleChange=(val)=>{
        handleChangeColor(val.target.name, val.target.value); 
    }

    return (
        <>
           
           <div className=" flex flex-col flex flex-col space-y-2">
                <label htmlFor={name} className="font-bold">{name}</label>
                <input onChange={handleChange} type="color" name={name} id={name} value={value} className="rounded w-[3.5rem] h-[2.5rem]"/>
           </div>
        
        </>
    )
}



