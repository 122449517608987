import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import {KindeProvider} from "@kinde-oss/kinde-auth-react";
import {router} from "../src/utils/router"; 
import { Loader } from 'rsuite'
import "./App.css"; 


const CLIENT_ID="825e8fa8469c4b958dcf32f2caeba126" // process.env.REACT_APP_CLIENT_ID
const DOMAIN="https://dane.kinde.com"; // process.env.REACT_APP_DOMAIN
const REDIRECT_URL="https://app.neyos.fr/"; // process.env.REACT_APP_REDIRECT_URL
const LOGOUTURL="https://app.neyos.fr/Auth"; // process.env.REACT_APP_LOGOUTURL

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <KindeProvider clientId={CLIENT_ID} domain={DOMAIN} redirectUri={REDIRECT_URL} logoutUri={LOGOUTURL}>
          <RouterProvider router={router} fallbackElement={<Loader speed="slow" size="lg" center content="NEYOS..."/>} />
      </KindeProvider>    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
