import L from "leaflet"; 
import {Tooltip, CircleMarker, Polyline } from "react-leaflet";

export default function WayPoints({data, handleGetDataWayPoint}) {
    if (!data || data.length === 0 || !data[0].series) {
        return null; // ou afficher un message d'erreur ou un loader
    }

    const value = data[0].series;

    // Crée un tableau qui va contenir toutes les positions (points)
    const polylinePositions = [];

    const displayData = value.map((bal) =>
        bal.values.map((d) => {
            const position = [d[1], d[2]]; // Récupère la latitude et longitude
            polylinePositions.push(position); // Ajoute les positions à la polyline

            return (
                <>
                    <CircleMarker center={position} radius={5} pathOptions={{fillColor: "blue"}} eventHandlers={{click:()=>handleGetDataWayPoint(d)}}>
                        <Tooltip>
                            <div className="flex flex-col">
                                <span><strong>alert</strong> {d[3]}</span>
                                <span><strong>bat</strong> {d[4]}</span>
                                <span><strong>timestamp</strong> {d[5]}</span>
                            </div>
                        </Tooltip>
                    </CircleMarker>
                </>
            );
        })
    );

    // Ajoute la Polyline après avoir accumulé toutes les positions
    return (
        <>
            {displayData}
            <Polyline pathOptions={{ color: "#4169E1" }} positions={polylinePositions}/>
        </>
    );
}
