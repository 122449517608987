import { useEffect, useState } from "react";
import { io } from "socket.io-client";

export function useWebSocket(latLong){
    
   

    const [LatLongData,setLatLongData]=useState(latLong); 

    /**
     * L'application tourne en temps réel...
     */ 
    const [onlive,setOnlive]=useState(false);

    const [resSocket,setResSocket]=useState([]); 
    
     

    useEffect(()=>{
         
        const socket= io(`https://app.neyos.fr/api/`);

         

        socket.on("HugoServer", (data) => {
            const newMarkerData = {
              imsi: data.data.ueExternalIdentifier,
              lat: data.data.lat,
              lon: data.data.lon,
              alert: data.data.alert,
              bat: data.data.bat,
              ts: data.data.ts
            };
      
            setLatLongData(prevData => [...prevData, newMarkerData]);
            setOnlive(true);
        });

        
        
    },[]); 


    
    /**
     * webSocketData : contient les données des balises nouvellements crée
     * onlive: status pour vérifier si la balise fonctionne en temps réel...
     * notificationAlert : message du serveur... 
    */
    
    return { LatLongData, onlive, resSocket}
    
}