import { NavLink } from "react-router-dom"
export default function NoAccess(){


    return (
        <>
            <div className="px-4 py-2 bg-red-100">
                <h1>Connexion requise</h1>
                <p className="text-md"> Vous devez être connecté pour accéder à cette page. Veuillez vous connecter <NavLink to="Auth/" className="text-blue-500">ici</NavLink></p>
            </div>
        </>
    )
}