import { Slider} from 'rsuite';
export default function SliderFilter({name, handleRange}){
    
    const handleChange=(val)=>{
        handleRange(name,val); 
    }

    return (
        <>
          <div className='flex flex-col space-y-2'>
            <p className='font-bold'>{name}</p>
            <Slider
                name={name}
                defaultValue={50}
                step={10}
                progress
                onChange={handleChange}
                />
          </div>
        </>
    )
}